import React from "reactn"

import { graphql } from "gatsby"
import { Layout } from "../../components/layoutSearch"
import { Seo } from "../../components/seo"
import Products from "../../components/products"
import { PageContent } from "../../components/pageContent"
export default function ProductsCollection({ location, data: { products } }) {
  const {
    descriptionHtml,
    image,
    fields,
    metaTitle,
    metaDescription
  } = products

  const {
    pageTitle,
    social_image,
    social_images
  } = fields

  return (
    <>
      <Seo metaTitle={metaTitle} metaDescription={metaDescription} socialImage={social_image} socialImages={social_images} og_type={'article'} location={location} />
      <PageContent h1Tag={metaTitle}>
        <Products title={pageTitle} html={descriptionHtml}/>
      </PageContent>
    </>
  )
}

ProductsCollection.Layout = Layout
export const query = graphql`
  query($id: String!) {
    products: shopifyCollection(id: { eq: $id }) {
      internal{
        type
      }
      metaTitle
      metaImage
      metaDescription
      fields {
        pageTitle
        social_image
        social_images
      }
      descriptionHtml
      image {
        gatsbyImageData(
          width: 100
        )
      }
    }
  }
`
